<template>
  <section class="content">
    <div class="row justify-content-center">
      <div class="col-md-12">
        <div class="card card-primary">
          <!-- /.card-header -->
          <!-- form start -->
          <form role="form" @submit.prevent="submitForm">
            <div class="invoice p-3 mb-3">
              <!-- /.row -->
              <div class="row">
                <div class="col-md-6 form-group">
                  <label class="control-label">LOKASI PENJEMPUTAN</label>
                  <div class="border rounded p-2 position-relative">
                    <div style="min-height: 50px">
                      <div v-if="sender.id">
                        <strong> {{ sender.senderName }} </strong><br />
                        {{ sender.senderTel }} ({{ sender.pic_name }})<br />
                        {{ sender.addr_detail }} <br />
                        {{ sender.kecamatan }}, {{ sender.kota }},
                        {{ sender.kodepos }}
                      </div>
                    </div>
                    <div
                      v-if="og_status != 1"
                      class="position-absolute"
                      style="right: 5px; top: 5px"
                    >
                      <button
                        type="button"
                        class="btn btn-secondary btn-sm"
                        v-on:click="selectWarehouse"
                      >
                        <i class="fa fa-map-pin"></i> Pilih Alamat
                      </button>
                    </div>
                    <input
                      type="hidden"
                      name="shipper_addr_id"
                      id="shipper_addr_id"
                      v-model="form.shipper_addr_id"
                    />
                  </div>
                </div>
                <div class="col-md-1"></div>
                <div class="col-md-3 form-group">
                  <label class="control-label">KURIR</label>
                  <select
                    required
                    v-model="form.courier_id"
                    id="courier_id"
                    name="courier_id"
                    class="form-control"
                    :disabled="shouldDisable"
                    @change="kurirChange($event)"
                  >
                    <option
                      v-for="courier in totalCourier"
                      v-bind:key="courier.courier_id"
                      v-bind:value="courier.courier_id"
                      v-bind:title="courier.drop_off"
                    >
                      {{ courier.nama_kurir }}
                    </option>
                  </select>
                </div>
                <div class="col-md-2"></div>
                <div class="col-md-3 form-group">
                  <label class="control-label">TIPE ORDER</label>
                  <div class="rowx">
                    <div
                      class="
                        form-check form-check-inline
                        icheck-material-teal icheck-inline
                      "
                    >
                      <input
                        class="form-check-input"
                        type="radio"
                        name="order_type"
                        id="order_type"
                        v-model="form.order_type"
                        @change="onChange($event)"
                        value="0"
                        :disabled="og_status == 1"
                      />
                      <label class="form-check-label" for="order_type"
                        >Pickup</label
                      >
                    </div>
                    <div
                      class="
                        form-check form-check-inline
                        icheck-material-teal icheck-inline
                      "
                    >
                      <input
                        class="form-check-input"
                        type="radio"
                        name="order_type"
                        id="order_type2"
                        v-model="form.order_type"
                        @change="onChange($event)"
                        value="1"
                        :disabled="droppOffDisabled"
                      />
                      <label class="form-check-label" for="order_type2"
                        >Drop Off</label
                      >
                    </div>
                  </div>
                </div>
                <div class="col-md-4 form-group" v-if="form.order_type == 0">
                  <label id="labelpickup_via1" class="control-label"
                    >JEMPUT MELALUI</label
                  >
                  <div class="rowx">
                    <div
                      class="
                        form-check form-check-inline
                        icheck-material-teal icheck-inline
                      "
                    >
                      <input
                        class="form-check-input"
                        type="radio"
                        required
                        id="pickup_via1"
                        name="pickup_via"
                        v-model="form.pickup_via"
                        value="1"
                        :disabled="og_status == 1"
                      />
                      <label
                        class="form-check-label"
                        id="labelpickup_via2"
                        for="pickup_via1"
                        >Motor</label
                      >
                    </div>
                    <div
                      class="
                        form-check form-check-inline
                        icheck-material-teal icheck-inline
                      "
                    >
                      <input
                        class="form-check-input"
                        type="radio"
                        required
                        id="pickup_via2"
                        name="pickup_via"
                        v-model="form.pickup_via"
                        value="2"
                        :disabled="og_status == 1"
                      />
                      <label
                        class="form-check-label"
                        id="labelpickup_via2"
                        for="pickup_via2"
                        >Mobil</label
                      >
                    </div>
                    <div
                      class="
                        form-check form-check-inline
                        icheck-material-teal icheck-inline
                      "
                    >
                      <input
                        class="form-check-input"
                        type="radio"
                        required
                        id="pickup_via3"
                        name="pickup_via"
                        v-model="form.pickup_via"
                        value="3"
                        :disabled="og_status == 1"
                      />
                      <label
                        class="form-check-label"
                        id="labelpickup_via3"
                        for="pickup_via3"
                        >Truk</label
                      >
                    </div>
                  </div>
                </div>
                <div class="col-md-3 form-group" v-if="form.order_type == 0">
                  <label class="control-label">WAKTU PENJEMPUTAN</label>
                  <input
                    type="text"
                    class="form-control"
                    required="true"
                    ref="daterange"
                  />
                </div>
              </div>
              <div class="row" v-if="form.order_type==0">
                  <span class="callout callout-warning"><strong>Catatan:</strong> Mohon jangan diantarkan ke cabang, kurir akan pickup ke tempat Anda.</span>
              </div>
              <div class="row" v-else>
								<span class="callout callout-warning">
                  <div class="row">
                    <div class="col-md-2">
                      <strong>Catatan:</strong>
                    </div>
                    <div class="row col-md-10">
                      Paket kiriman diantar sendiri sama kamu ke agen ekspedisi terdekat. <br>
                      <strong style="color: blue;" v-on:click="showItemDropOff"> Lihat Selengkapnya</strong>
                    </div>
                  </div>
								</span>
							</div>
              <div class="row">
                <div class="col-md-6 form-group">
                  <label class="control-label">CATATAN</label>
                  <input
                    id="note"
                    class="form-control"
                    v-model="form.note"
                    type="text"
                    name="note"
                    onkeydown="return (event.ctrlKey || event.altKey 
                    || (47<event.keyCode && event.keyCode<58 && event.shiftKey==false) || (event.keyCode==57  && event.shiftKey==true)
                    || (95<event.keyCode && event.keyCode<106)|| (event.keyCode==8) || (event.keyCode==9) || (event.keyCode==48  && event.shiftKey==true)
                    || (event.keyCode==190  && event.shiftKey==false) || (event.keyCode==188  && event.shiftKey==false)
                    || (34<event.keyCode && event.keyCode<40) || (event.keyCode==32) || (event.keyCode==46) || (event.keyCode==13) || (64<event.keyCode && event.keyCode<91))"
                  />
                </div>
              </div>
              <div class="p-2"></div>
              <!-- form order items -->
              <h4 class="mb-2">
                <i class="fa fa-tags"></i> &nbsp; Data Pesanan
              </h4>
              <div v-if="og_status == 1" class="text-rightx"></div>
              <div v-else class="text-rightx">
                <button
                  type="button"
                  v-on:click="uploadItem"
                  data-action="create"
                  class="btn btn-info mr-1"
                  :title="(!form.courier_id || !sender.id)?'Pilih Lokasi Penjemputan dan Kurir':'Upload Data'"
                  :disabled="!form.courier_id || !sender.id"
                >
                  <i class="fas fas fa-upload"></i> Upload Pesanan
                </button>
                <button
                  class="btn btn-secondary mr-1"
                  @click.prevent="downloadTemplate"
                >
                  <i class="fa fa-download"></i> Unduh Template
                  <span
                    v-if="loadingDownload"
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                </button>
                <button
                  class="btn btn-secondary mr-1"
                  @click.prevent="downloadKode"
                >
                  <i class="fa fa-download"></i> Kode Tujuan
                  <span
                    v-if="loadingDownload2"
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                </button>
                <a href="https://help.boscod.com/video-cara-membuat-pesanan-massal-upload" class="btn btn-info mr-1" target="_blank" rel="noopener noreferrer"><i class="fas fa-question-circle"></i> Tutorial</a>
              </div>
              <div class="table-responsive">
                <table class="table table-hover" id="tblorder" ref="tableorder">
                  <thead>
                    <tr>
                      <th>Id</th>
                      <th>PENERIMA</th>
                      <th>TELEPON</th>
                      <th>PROPINSI</th>
                      <th>KOTA</th>
                      <th>KECAMATAN</th>
                      <th>KODE TUJUAN</th>
                      <th>BERAT</th>
                      <th>NILAI</th>
                      <th>COD</th>
                      <th>ACTION</th>
                    </tr>
                  </thead>
                  <tbody @click="handleClick"></tbody>
                </table>
              </div>
            </div>
            <!-- form select pickup address per shipper id -->
            <Warehouse :show="show" v-model="sender" />
            <!-- /.card-body -->
            <div class="card-footer">
              <div v-if="og_status == 1">
                <div class="box-tools card-tools toolbar mr-2"></div>
              </div>
              <button
                v-if="og_status != 1"
                type="submit"
                @click="submitType = 'submit'"
                id="submit"
                class="btn btn-primary mr-2"
                :disabled="submitting1"
              >
                <i class="fas fa-check"></i> Submit Pesanan
                <span
                  v-if="submitting1"
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              </button>
              <button
                v-if="og_status != 1"
                type="submit"
                @click="submitType = 'draft'"
                id="draft"
                class="btn btn-success mr-2"
                :disabled="submitting2"
              >
                <i class="fas fa-edit"></i> Simpan Draft
                <span
                  v-if="submitting2"
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              </button>
              <router-link
                to="/order"
                class="btn btn-secondary ml-2 float-right d-none d-sm-block"
              >
                <i class="fas fa-arrow-left"></i> Kembali
              </router-link>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- Upload Item -->
    <div
      class="modal"
      tabindex="-1"
      role="dialog"
      ref="formUpload"
      data-backdrop="static"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Upload File</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="drag-area">
              <div class="icon">
                <i class="fas fa-cloud-upload-alt"></i>
              </div>
              <header>Upload File Here</header>
              <button id="btnFile" @click="btnFile">Pilih File</button>
              <input
                type="file"
                id="excelFile"
                @change="excelImport"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                hidden
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal" tabindex="-1" role="dialog" ref="formDialogDropOff" data-backdrop="static">
			<div class="modal-dialog" role="document">
				<div class="modal-content">
					<form role="form" @submit.prevent="saveItem">
						<div class="modal-header">
							<h4 class="modal-title">Tentang Pengiriman Drop Off</h4>
							<button type="button" class="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div class="modal-body">
							<div class="row" style="align-items: center;">
								<!-- <div class="col-md-1" >
									<h1 style="margin-left: 15px;">.</h1>
								</div>
                <div class="col-md-11">
									JNE hanya bisa drop off melalui kantor cabang, tidak bisa di counter
								</div> -->
                <div class="col-md-1" >
									<h1 style="margin-left: 15px;">.</h1>
								</div>
                <div class="col-md-11">
									SAP hanya bisa drop off melalui kantor cabang, tidak bisa di counter
								</div>
                <div class="col-md-1" >
									<h1 style="margin-left: 15px;">.</h1>
								</div>
                <div class="col-md-11">
									IDX bisa melalui counter atau AlfaMart
								</div>
                <div class="col-md-1" >
									<h1 style="margin-left: 15px;">.</h1>
								</div>
                <div class="col-md-11">
									Sicepat bisa melalui counter
								</div>
                <div class="col-md-1" >
									<h1 style="margin-left: 15px;">.</h1>
								</div>
                <div class="col-md-11">
									JNT bisa melalui counter terdekat
								</div>
							</div>
						</div>
						<div class="modal-footer" style="align-items: center; justify-content: center; text-align: center;">
							<button type="button" class="btn btn-primary" data-dismiss="modal" style="width: 100%;">Mengerti</button>
						</div>
					</form>
				</div>
			</div>
		</div>
  </section>
</template>
<script>
import Vue from "vue";
import XLSX from "xlsx";
import { createTable, authFetch } from "@/libs/hxcore";
import Swal from "sweetalert2";
import $ from "jquery";
import ToggleButton from "vue-js-toggle-button";
import Warehouse from "@/dialog/Warehouse";
import vSelect from "vue-select";
import maskedInput from "vue-masked-input";
import { CurrencyInput } from "vue-currency-input";
import "dropzone/dist/dropzone.css";
import "vue-select/dist/vue-select.css";
import datepicker from "@/components/Datepicker";
import timepicker from "@/components/Timepicker";
import "pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css";
import datePicker from "vue-bootstrap-datetimepicker";
import moment from "moment";
import Daterangepicker from "daterangepicker";
import "icheck-material";
import "daterangepicker/daterangepicker.css";

Vue.use(ToggleButton);

export default {
  data() {
    return {
      errors: [],
      loadingDownload: false,
      loadingDownload2: false,
      shouldDisable: false,
      codValueErr: false,
      destTelErr: false,
      totalCourier: [],
      addrOptions: [],
      pickupTimeOpt: [],
      loading: false,
      roles: "",
      groups: [],
      show: false,
      method: "POST",
      form: {
        pickup_time: "",
        pickup_via: "",
        note: "",
        courier_id: "",
        code: "",
        shipper_addr_id: "",
        sender_name: "",
        sender_addr_id: "",
        sender_address: "",
        sender_postcode: "",
        sender_addr_code: "",
        sender_phone: "",
        submit: "",
        order_type: "0",
        pickupend_on: "",
      },
      formItem: {
        //order
        id: "",
        destName: "",
        destTel: "",
        destCity: "",
        destCode: "",
        receiver_postcode: "",
        addr_id: "",
        addr_detail: "",
        item_name: "",
        weight: "",
        length: "",
        width: "",
        height: "",
        note: "",
        insurance_type: "",
        codValue: 0,
        codStatus: true,
        nilai_ongkir: 0,
      },
      submitting1: false,
      submitting2: false,
      og_status: "",
      sender: {},
      excelData: [],
      droppOffDisabled: true,
      verified_ktp: false
    };
  },
  created: function () {
    // alert(this.$route.params.id);
    if (this.$route.params.id) {
      this.loadOrderGroup(this.$route.params.id);
      this.shouldDisable = true;
    } else {
      this.loadAddress();
    }
    var now = moment();
    var nowH = moment().format("H");

    //get data courier for dropdown select
    authFetch("/master/courier/groups")
      .then((res) => {
        if (res.status === 201) {
        } else if (res.status === 400) {
        }
        return res.json();
      })
      .then((js) => {
        this.totalCourier = js.data;
        $('.loading-overlay').removeClass('show');
      });
  },
  methods: {
    downloadKode: function () {
      this.loadingDownload2 = true;
      authFetch("/master/destination/code_all", {
        method: "GET",
      })
        .then((response) => response.blob())
        .then((blob) => {
          this.loadingDownload2 = false;
          var url = window.URL.createObjectURL(blob);
          var a = document.createElement("a");
          a.href = url;
          a.download = "KODE_TUJUAN.csv";
          document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
          a.click();
          a.remove(); //afterwards we remove the element again
        });
    },
    downloadTemplate: function () {
      this.loadingDownload = true;
      authFetch("/file/aset/template", {
        method: "GET",
      })
        .then((response) => response.blob())
        .then((blob) => {
          this.loadingDownload = false;
          var url = window.URL.createObjectURL(blob);
          var a = document.createElement("a");
          a.href = url;
          a.download = "template.xlsx";
          document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
          a.click();
          a.remove(); //afterwards we remove the element again
        });
    },
    kurirChange(event) {
      // console.log("=====> ", event);
      // console.log("=====> ", event.target);
      // console.log("=====> ", event.target.selectedOptions[0].title);
      var items = [];

      if (event.target.selectedOptions[0].title == 1 && this.verified_ktp == true){
        this.droppOffDisabled = false;
      }else{
        this.droppOffDisabled = true;
        this.form.order_type = "0";
      }

      this.tbl
        .api()
        .rows()
        .every(function (rowIdx, tableLoop, rowLoop) {
          var d = this.data();
          items.push(d);
        });
      if (items.length > 0) {
        Swal.fire({
          title: "Konfirmasi",
          text: "Perubahan jenis kurir mengharuskan entri kembali item pesanan.",
          showCancelButton: true,
          confirmButtonText: `Lanjutkan`,
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.tbl.api().clear().draw();
            this.prevCourier = event.target.value;
          } else {
            this.form.courier_id = this.prevCourier;
          }
        });
        return;
      }
      this.prevCourier = event.target.value;
    },
    btnFile() {
      var input = document.querySelector("#excelFile");
      input.click(); //if user click on the button then the input also clicked
    },
    onChange(event) {
      // if (event.target.value == 1) {
        this.form.pickup_via = event.target.value;
      // } else {
      // }
    },
    onToggleChange(event) {
      // added event as second arg
      this.formItem.codStatus = event.value;
    },
    excelImport(event) {
      const e = this.$refs;
      var input = event.target;
      var reader = new FileReader();
      reader.onload = () => {
        var fileData = reader.result;
        var wb = XLSX.read(fileData, { type: "binary" });
        var worksheet = wb.Sheets[wb.SheetNames[0]];
        var rowObj = XLSX.utils.sheet_to_json(worksheet);
        let data = rowObj;

        if (
          !data[0].NAMA_PENERIMA ||
          !data[0].TELEPON ||
          !data[0].ALAMAT_LENGKAP ||
          !data[0].PROPINSI ||
          !data[0].KOTA ||
          !data[0].KECAMATAN ||
          !data[0].KODE_TUJUAN ||
         //!data[0].KODEPOS ||
          !data[0].NAMA_PRODUK ||
          !data[0].BERAT ||
          !data[0].ASURANSI
        ) {
          Swal.fire("Proses gagal", `data atau kolom tidak sesuai!`, "error");
        } else {
          for (var i = 0; i < data.length; i++) {
            let nRow = this.tbl.fnAddRow(data);
            let namaLengkap = data[i].NAMA_PENERIMA;

            let codvalue = "";
            let status = "";
            if (!data[i].NILAI_COD) {
              status = 0;
              codvalue = data[i].HARGA_PRODUK;
            } else {
              status = 1;
              codvalue = data[i].NILAI_COD;
            }

            let insuranceType = 0;

            //cek asuransi
            if (this.form.courier_id == 3) {
              insuranceType = 1;
            } else if (this.form.courier_id != 3) {
              insuranceType = data[i].ASURANSI == "NO" ? 0 : 1;
            }

            var aData = {
              id: this.formItem.id,
              order_code: !data[i].ORDER_ID ? "" : data[i].ORDER_ID,
              destName: namaLengkap,
              destTel: !data[i].TELEPON ? "" : data[i].TELEPON,
              // destCity: "",
              destCode: !data[i].KODE_TUJUAN ? "" : data[i].KODE_TUJUAN.toString(),
              //receiver_postcode: !data[i].KODEPOS ? "" : data[i].KODEPOS.toString(),
              receiver_propinsi: !data[i].PROPINSI ? "" : data[i].PROPINSI,
              receiver_kota: !data[i].KOTA ? "" : data[i].KOTA,
              receiver_kecamatan: !data[i].KECAMATAN ? "" : data[i].KECAMATAN,
              addr_id: "",
              addr_detail: !data[i].ALAMAT_LENGKAP ? "" : data[i].ALAMAT_LENGKAP,
              item_name: !data[i].NAMA_PRODUK ? "" : data[i].NAMA_PRODUK,
              item_qty: !data[i].QUANTITY ? "" : data[i].QUANTITY,
              weight: !data[i].BERAT ? "" : data[i].BERAT,
              length: "",
              width: "",
              height: "",
              note: !data[i].INSTRUKSI ? "" : data[i].INSTRUKSI,
              nilai_ongkir: 0,
              insurance_type: insuranceType,
              codValue: codvalue,
              codStatus: status,
              extfield1: data[i].CATATAN_LAIN,
              action: "",
            };
            this.tbl.fnUpdate(aData, nRow);
            $("td .btn.edit", nRow).click();
          }
        }
        $(e.formUpload).modal("hide");
      };
      reader.readAsBinaryString(input.files[0]);
    },
    loadAddress(id) {
      let route;
      if (id) {
        route = "/order/address/" + id;
      } else {
        route = "/order/address/0";
      }
      authFetch(route).then((res) => {
        res.json().then((json) => {
          if (json.success) {
            this.sender = json.data;
            this.addrOptions.push({
              id: json.data.addr_id,
              lokasi: json.data.kota + " / " + json.data.kecamatan,
            });
          }
        });
      });
    },
    loadOrderGroup(id) {
      authFetch("/order/group/" + id).then((res) => {
        res.json().then((json) => {
          this.form = json;

          if (this.form.order_type == "1") {
            this.form.pickup_via = null;
            this.form.pickup_time = null;
            this.form.pickupend_on = null;
          }

          this.og_status = json.og_status;
          this.loadAddress(json.shipper_addr_id);
        });
      });
    },
    onSearch(search, loading) {
      if (search.length > 3) {
        loading(true);
        //this.search(loading, search, this);
        var vm = this;
        authFetch(`/order/wilayah?q=${escape(search)}`).then((res) => {
          res.json().then((json) => (vm.addrOptions = json.items));
          loading(false);
        });
      }
    },
    showItemDropOff: function() {
      const e = this.$refs;
      $(e.formDialogDropOff).modal("show");
	  },
    loadDataUserDropOff() {
      let route = "/order/shipper_tag";
      try {
        authFetch(route).then(res => {
          res.json().then(json => {
            this.verified_ktp = json.info.drop_off;
            // console.log("ini ya popo ", json.info.drop_off);
            // console.log("og_status ", this.og_status);
            if (json.success == true && json.info.drop_off == false){
              this.droppOffDisabled = true;
            } else {
              this.droppOffDisabled = false;
            }
          });
        });
        
      } catch (error) {
        
      }
    },
    uploadItem: function () {
      const e = this.$refs;
      $(e.formUpload).modal("show");
    },
    selectWarehouse: function () {
      this.show = !this.show;
    },
    submitForm: function (ev) {
      this.$gtag.event(`click`, {
        'event_name': 'Import Order Group'
      });
      const self = this;
      this.form.shipper_addr_id = this.sender.id;
      this.form.sender_name = this.sender.senderName;
      this.form.sender_addr_id = this.sender.addr_id;
      this.form.sender_addr_code = this.sender.addr_code;
      this.form.sender_address = this.sender.addr_detail;
      this.form.sender_postcode = this.sender.kodepos;
      this.form.sender_phone = this.sender.senderTel;
      this.form.code = this.sender.code;

      var data = this.tbl.getSerialize();

      self.form.id = self.$route.params.id;
      if (ev.submitter) {
        self.form.submit = ev.submitter.id;
      } else {
        self.form.submit = this.submitType;
      }

      var setmethod = self.form.id ? "PUT" : "POST";

      var items = [];
      this.tbl
        .api()
        .rows()
        .every(function (rowIdx, tableLoop, rowLoop) {
          var d = this.data();
          items.push(d);
        });

      var allData = { group: self.form, items: items };

      if (self.form.submit == "draft") {
        this.submitting2 = true;
        var urlSubmit = "/order/import_group";
        if (self.method == "PUT")
          urlSubmit = "/order/import_group/" + self.form.id;

        authFetch(urlSubmit, {
          method: setmethod,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(allData),
        })
          .then((res) => {
            if (res.status === 201) {
            } else if (res.status === 400) {
            }
            return res.json();
          })
          .then((js) => {
            this.errors = [];
            if (!js.success) {
              this.submitting2 = false;
              //Swal.fire("Proses gagal", `${js.msg}`, "error");
              let detailData = "";
              if (js.errors) {
                detailData =
                  '<div style="overflow-y: auto;max-height:150px;" class="error-detail border rounded p-2 mt-2 text-muted text-left">';
                for (let i in js.errors) {
                  detailData +=
                    "<b>Kode " +
                    js.errors[i].orderCode +
                    "</b><br><i>" +
                    js.errors[i].desc +
                    "</i><br>";
                }
                detailData += "</div>";
              }

              var finalMsg =
                '<i class="fa fa-exclamation-triangle"></i> ' +
                js.msg +
                detailData;
              Swal.fire({
                title: "Data gagal diimport",
                icon: "error",
                html: finalMsg,
                showCloseButton: true,
              });

              for (var key in js.details) {
                if (js.details.hasOwnProperty(key)) {
                  this.errors.push(js.details[key]);
                }
              }

              return;
            } else {
              if (js.errors.length == 0) this.$router.push("/order");
              else {
                let detailData =
                  '<div style="overflow-y: auto;max-height:150px;" class="error-detail border rounded p-2 mt-2 text-muted text-left">';
                for (let i in js.errors) {
                  detailData +=
                    "<b>Kode " +
                    js.errors[i].orderCode +
                    "</b><br><i>" +
                    js.errors[i].desc +
                    "</i><br>";
                }
                detailData += "</div>";

                var finalMsg =
                  '<i class="fa fa-exclamation-triangle"></i> Ada item pesanan yang gagal diimport.' +
                  detailData;
                Swal.fire({
                  title: "Data berhasil diimport",
                  icon: "success",
                  html: finalMsg,
                  showCloseButton: true,
                });
              }
            }
          });
      } else if (self.form.submit == "submit") {
        Swal.fire({
          title: "Submit Pesanan?",
          showCancelButton: true,
          confirmButtonText: `Submit`,
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.submitting1 = true;
            var url = "/order/import_group";
            if (self.method == "PUT")
              url = "/order/import_group/" + this.$route.params.id;
            authFetch(url, {
              method: setmethod,
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
              body: JSON.stringify(allData),
            })
              .then((res) => {
                this.submitting1 = true;
                return res.json();
              })
              .then((js) => {
                if (js.success == true) {
                  this.submitting1 = false;
                  let dataorder = js.errors;
                  let countSuccess = 0;
                  let countFail = 0;
                  let finalMsg = "";

                  if (js.errors.length > 0) {
                    let detailData =
                      '<div style="overflow-y: auto;max-height:150px;" class="error-detail border rounded p-2 mt-2 text-muted text-left">';
                    for (let i in js.errors) {
                      detailData +=
                        "<b>Kode " +
                        js.errors[i].orderCode +
                        "</b><br><i>" +
                        js.errors[i].desc +
                        "</i><br>";
                    }
                    detailData += "</div>";

                    finalMsg =
                      '<i class="fa fa-exclamation-triangle"></i> Ada item pesanan yang gagal dibuat.' +
                      detailData;
                  } else {
                    finalMsg = "Semua item pesanan berhasil dibuat.";
                  }

                  Swal.fire({
                    title: "Transaksi Berhasil",
                    icon: "success",
                    html: finalMsg,
                    showCloseButton: true,
                  });
                  this.$router.push("/order");
                } else {
                  this.submitting1 = false;
                  let detailData = "";
                  if (js.errors) {
                    if (js.errors[0].desc == 'jnt'){
                      detailData =
                        '<div style="overflow-y: auto;max-height:150px;" class="error-detail border rounded p-2 mt-2 text-muted text-left">';
                      for (let i in js.errors) {
                        detailData +=
                          "<b>Kode " +
                          js.errors[i].orderCode +
                          "</b><br><i>" +
                          js.errors[i].desc +
                          "</i><br>";
                      }
                      detailData += "</div>";
                      var finalMsg =
                        '<i class="fa fa-exclamation-triangle"></i> ' + 'Maaf untuk saat ini JNT masih belum bisa melakukan penjemputan di daerah mu. Gunakan kurir ekspedisi yang lain.'
                      Swal.fire({
                        title: "Area penjemputan tidak tersedia",
                        width: '600px',
                        icon: "error",
                        html: finalMsg,
                        showCloseButton: true,
                      });
                      return;
                    } else {
                      detailData =
                      '<div style="overflow-y: auto;max-height:150px;" class="error-detail border rounded p-2 mt-2 text-muted text-left">';
                    for (let i in js.errors) {
                      detailData +=
                        "<b>Kode " +
                        js.errors[i].orderCode +
                        "</b><br><i>" +
                        js.errors[i].desc +
                        "</i><br>";
                    }
                    detailData += "</div>";
                    var finalMsg =
                      '<i class="fa fa-exclamation-triangle"></i> ' +
                      js.msg +
                      detailData;
                    Swal.fire({
                      title: "Terjadi kesalahan",
                      icon: "error",
                      html: finalMsg,
                      showCloseButton: true,
                    });
                    return;
                    }
                  }

                  if (js.msg) {
                    Swal.fire("Proses gagal", `${js.msg}`, "error");
                  } else {
                    for (let i = 0; i < js.output.length; i++) {
                      Swal.fire("Proses gagal", `${js.output[i].msg}`, "error");
                      this.$router.push("/order");
                    }
                  }
                }
              });
          }
        });
      }
      ev.preventDefault();
    },
    handleClick(e) {
      const evt = this.$refs;
      if (e.target.matches("button")) {
        if (e.target.dataset.action == "view") {
          this.$router.push("/order/detail/" + e.target.dataset.id);
        } else if (e.target.dataset.action == "editsubmit") {
          this.$router.push("/order/edititem/" + e.target.dataset.id);
        } else if (e.target.dataset.action == "remove") {
          var nRow = $(e.target).closest("tr").get(0);
          this.tbl.fnDeleteRow(nRow);
        } else if (e.target.dataset.action == "edit") {
          var nRow = $(e.target).closest("tr").get(0);
          var iRow = this.tbl.fnGetPosition(nRow);
          var aData = this.tbl.fnGetData(iRow);

          this.addrOptions.push({
            id: aData.addr_id,
            lokasi: aData.destCity + " / " + aData.kecamatan,
          });

          this.formItem = {
            nRow: nRow,
            id: aData.id,
            destName: aData.destName,
            destTel: aData.destTel,
            destCity: aData.destCity,
            addr_id: aData.addr_id,
            addr_detail: aData.addr_detail,
            item_name: aData.item_name,
            weight: aData.weight,
            length: aData.length,
            width: aData.width,
            height: aData.height,
            insurance_type: aData.insurance_type == "1" ? true : false,
            note: aData.note,
            codValue: aData.codValue,
            nilai_ongkir: aData.nilai_ongkir,
            codStatus: aData.codStatus == "1" ? true : false,
          };

          $("#append").hide();
          $("#update").show();
          $(evt.formDialog).modal("show");
        } else if (e.target.dataset.action == "submit") {
          const self = this;

          self.formItem.submit = e.submitter.id;
          self.formItem.id = e.target.dataset.id;

          var setmethod = self.formItem.id ? "PUT" : "POST";

          var allData = { item: self.formItem };

          if (self.formItem.submit == "submit") {
            Swal.fire({
              title: "Submit Pesanan?",
              showCancelButton: true,
              confirmButtonText: `Submit`,
            }).then((result) => {
              /* Read more about isConfirmed, isDenied below */
              if (result.isConfirmed) {
                // this.submitting1 = true;
                var url = "/order/item";
                if (self.method == "PUT")
                  url = "/order/item/" + this.$route.params.id;
                authFetch(url, {
                  method: setmethod,
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify(allData),
                })
                  .then((res) => {
                    // this.submitting1 = true;
                    return res.json();
                  })
                  .then((js) => {
                    if (js.success) {
                      Swal.fire(
                        "Proses Berhasil",
                        `total: ${js.total} <br>
                  data: <br>
                  data: ${js.msg}`,
                        "success"
                      );
                      this.$router.push(
                        "/order/edit/" + self.formItem.group_id
                      );
                    } else {
                      // this.submitting1 = false;
                      Swal.fire("Proses gagal", `${js.msg}`, "error");
                    }
                  });
              }
            });
          }
        }
        return false;
      }
    },
  },
  components: {
    datepicker,
    timepicker,
    vSelect,
    maskedInput,
    CurrencyInput,
    Warehouse,
    datePicker,
  },
  mounted() {
    const e = this.$refs;
    const self = this;
    var now = moment();
    var minDate = now.startOf("hour").add(1, "h");
    if (now.format("H") >= 20) {
      minDate = minDate.add(1, "d").startOf("date").add(8, "h");
    }
    if (!this.$route.params.id) {
      this.form.pickup_time = minDate.format("YYYY-MM-DD HH:mm:00");
    }
    new Daterangepicker(
      this.$refs.daterange,
      {
        singleDatePicker: true,
        showDropdowns: true,
        timePicker: true,
        timePicker24Hour: true,
        timePickerIncrement: 60,
        startDate: minDate,
        minDate: minDate,
        locale: {
          format: "DD/MM/YYYY HH:mm",
        },
      },
      function (d1, d2) {
        self.form.pickup_time = d1.format("YYYY-MM-DD HH:mm:00");
      }
    );

    this.tbl = createTable(e.tableorder, {
      title: "",
      serverSide: false,
      ajax: "/order",
      paramData: function (d) {
        d.group_id = 0;
      },
      selectedRowClass: "",
      frame: false,
      displayLength: 100,
      lengthChange: false,
      filter: false,
      columns: [
        { data: "id", visible: false },
        { data: "destName" },
        { data: "destTel" },
        { data: "receiver_propinsi" },
        { data: "receiver_kota" },
        { data: "receiver_kecamatan" },
        { data: "destCode" },
        //{ data: "receiver_postcode" },
        { data: "weight" },
        { data: "codValue" },
        { data: "codStatus" },
        { data: "addr_id" },
        { data: "item_name", visible: false },
        { data: "length", visible: false },
        { data: "width", visible: false },
        { data: "height", visible: false },
        { data: "insurance_type", visible: false },
        { data: "note", visible: false },
        { data: "nilai_ongkir", visible: false },
        { data: "addr_id", visible: false },
        { data: "addr_detail", visible: false },
      ],
      rowCallback: function (row, data) {
        var strAction =
          `<div class="btn-group">
         
                    <button type="button" class="btn btn-sm btn-outline-danger" data-action="remove" data-id="` +
          data.id +
          `"><i class="fas fa-trash"></i> Hapus</button>
                  </div>`;

        if (data.status == "1" && !data.no_resi) {
          $("td:eq(9)", row).html(strAction);
        } else if (data.status == "1" && data.no_resi != "") {
          $("td:eq(9)", row).html(
            `<div class="btn-group">
          <button type="button" class="btn btn-sm btn-outline-info" data-action="view" data-id="` +
              data.order_code +
              `"><i class="fas fa-eye"></i> Lihat detail</button>
              </div>`
          );
        } else {
          $("td:eq(9)", row).html(
            `<div class="btn-group">
                    <button type="button" class="btn btn-sm btn-outline-danger" data-action="remove" data-id="` +
              data.id +
              `"><i class="fas fa-trash"></i> Hapus</button>
                  </div>`
          );
        }

        let statusCod = "";
        if (data.codStatus == true) {
          statusCod = true;
        } else {
          statusCod = false;
        }

        if (statusCod == true) {
          $("td:eq(8)", row).html(
            `<span class="badge badge-success">YA</span>`
          );
        } else {
          $("td:eq(8)", row).html(
            `<span class="badge badge-warning">TIDAK</span>`
          );
        }
      },
    }).fnEditor({
      action:
        '<div class="btn-group"><a class="btn edit btn-xs icon-edit btn-primary"><i class="fas fa-pen"></i> Edit</a><a class="btn delete btn-xs icon-remove btn-danger"><i class="fa fa-trash"></i> Hapus</a></div>',
    });
    this.loadDataUserDropOff();
  },
};
</script>
<style scoped>
.drag-area {
  border: 2px dashed rgb(114, 114, 114);
  min-height: 250px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.drag-area.active {
  border: 2px solid rgb(133, 133, 133);
}

.drag-area .icon {
  font-size: 100px;
  color: rgb(139, 139, 139);
}

.drag-area header {
  font-size: 30px;
  font-weight: 500;
  color: rgb(126, 126, 126);
}

.drag-area span {
  font-size: 25px;
  font-weight: 500;
  color: rgb(129, 129, 129);
  margin: 10px 0 10px 0;
}

.drag-area button {
  padding: 10px 20px;
  font-size: 20px;
  font-weight: 500;
  border: none;
  outline: none;
  background: rgb(206, 206, 206);
  color: #5256ad;
  border-radius: 5px;
  cursor: pointer;
}

.drag-area img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 5px;
}
.error-detail {
  font-size:12px;
}
</style>